<template>
  <header
    ref="headerRef"
    v-body-scroll-lock="isOpen"
    class="bg-blue sticky top-0"
    :class="{'!absolute inset-x-0 overflow-y-auto max-lg:h-screen': isOverlay}"
  >
    <div class="bg-white py-2">
      <nav
        role="navigation"
        class="container flex items-center justify-between"
      >
        <NuxtLink
          class="link-escape"
          :to="localePath('/')"
          aria-label="Homepagina"
        >
          <IconsLogo />
        </NuxtLink>

        <!-- Mobile menu -->
        <button
          v-if="!isOverlay"
          class="lg:hidden"
          aria-controls="mobile-menu"
          aria-expanded="false"
          @click="openMobile"
        >
          <span class="sr-only">{{ $t('Open mobile menu') }}</span>
          <IconsActionsMenuBar aria-hidden="true" />
        </button>
        <button
          v-if="isOverlay"
          class="lg:hidden"
          aria-controls="mobile-menu"
          aria-expanded="false"
          @click="closeMobile"
        >
          <span class="sr-only">{{ $t('Close mobile menu') }}</span>
          <IconsActionsClose aria-hidden="true" />
        </button>

        <!-- Desktop menu -->
        <nav class="hidden lg:block lg:space-y-5">
          <div class="flex justify-end gap-7">
            <!--<LayoutHeaderLocaleSwitcher />-->
            <NuxtLink
              class="link-header text-blue-lighter uppercase !underline"
              :to="registerUrl"
            >
              {{ $t('Join') }}
            </NuxtLink>
            <NuxtLink
              class="link-header text-blue-lighter uppercase !underline"
              :to="loginUrl"
            >
              {{ $t('Login') }}
            </NuxtLink>
          </div>
          <LayoutHeaderNavLinks
            :nav-items="navItems"
            class="container px-0"
          />
        </nav>
      </nav>

      <LayoutHeaderCrumbs class="container pt-2.5 max-lg:hidden" />
    </div>

    <!-- Mobile menu open -->
    <div
      v-if="isOverlay"
      id="mobile-menu"
      class="lg:hidden"
    >
      <nav class="container space-y-8 py-4">
        <LayoutHeaderNavLinks :nav-items="navItems" />
        <!--<LayoutHeaderLocaleSwitcher />-->
        <LayoutAuthAndSocialLinks />
      </nav>
    </div>

    <TransitionsFromTop>
      <AppHeader
        v-if="!isOverlay && isOpen"
        is-overlay
        @close="closeMobile"
      />
    </TransitionsFromTop>
  </header>
</template>

<script lang="ts" setup>
import {useAmbitionsQuery} from '~/graphql/graphql';

const props = defineProps<{isOverlay?: boolean}>();
const emit = defineEmits<{(e: 'close'): void}>();

const localePath = useLocalePath();

const config = useRuntimeConfig();
const loginUrl = config.public.loginUrl;
const registerUrl = config.public.registerUrl;

const {headerRef} = useHeaderOffset();
const route = useRoute();

const isOpen = ref(props.isOverlay);

const openMobile = () => isOpen.value = true;

function closeMobile() {
  isOpen.value = false;
  emit('close');
}

watch(() => route.fullPath, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    closeMobile();
  }
});

const {result} = useAmbitionsQuery();
const ambitions = computed(() => result.value?.ambitions ?? []);

const {menus} = useMenu();
const navItems = computed(() => {
  return menus.value.header.map((item) => {
    if (['Nieuws', 'News', 'Nachricht'].includes(item.text)) {
      return {
        ...item,
        children: ambitions.value.map((ambition) => {
          return {
            text: ambition.title,
            url: localePath({name: 'news', query: {ambition: ambition.id}}),
          };
        }),
      };
    }
    return item;
  });
});
</script>
